import * as React from 'react';
import ReactDOM from 'react-dom';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';

function App() {
	const [open, setOpen] = React.useState(true);
	const [customFields, setCustomFields] = React.useState(null);
	const [alertMsg, setAlertMsg] = React.useState({});
	const [submitted, setSubmitted] = React.useState(false);

	React.useEffect(() => {
		(async () => {
			if (customFields !== null) { return; }

			const fieldsJson = await fetch('/fields');
			const fields = await fieldsJson.json();
			setCustomFields(fields);
			setOpen(false);
		})();
	}, [customFields === null]);
	const [formInput, setFormInput] = React.useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			name: "",
			phone: "",
			email: "",
			title: "",
			description: "",
			region: "",
			category: ""
		}
	);
	const handleInput = evt => {
		const name = evt.target.name;
		const newValue = evt.target.value;
		setFormInput({ [name]: newValue });
	};
	const handleSubmit = evt => {
		evt.preventDefault();
		(async () => {
			setOpen(true);
			try {
				const respJson = await fetch('/new-ticket', { method: 'POST', body: JSON.stringify(formInput), headers: {  "Content-Type": "application/json" } })
				const resp = await respJson.json();
				setAlertMsg({
					severity: 'success',
					title: 'Success',
					text: resp?.message ?? 'New Ticket has been submitted'
				});
			} catch (e) {
				setAlertMsg({
					severity: 'error',
					title: 'Error',
					text: 'Issue Submitting Form'
				});
				console.log(e);
			}
			setSubmitted(true);
			setOpen(false);
		})();
	};
	return (
		<Box component="form" autoComplete="off" onSubmit={handleSubmit}>
			<Card sx={{ maxWidth: 480, margin: '0 auto' }}>
				<CardContent>
					<Typography variant="h4" component="h2" sx={{ textAlign: 'center' }}>Step by Step<br />Tech Support Request</Typography>
					<TextField onChange={handleInput} name="name" margin="normal" required fullWidth label="Requester Name" variant="outlined" />
					<TextField onChange={handleInput} name="phone" margin="normal" required fullWidth type="tel" label="Requester Phone" variant="outlined" />
					<TextField onChange={handleInput} name="email" margin="normal" fullWidth type="email" label="Requester Email Address" variant="outlined" />
					<TextField onChange={handleInput} name="title" margin="normal" required fullWidth label="Request Title" variant="outlined" />
					<TextField onChange={handleInput} name="description" margin="normal" fullWidth multiline rows={5} label="Request Description" variant="outlined" />
					<TextField onChange={handleInput} name="region" margin="normal" required fullWidth select label="Region" variant="outlined">
						{(customFields?.regions ?? []).map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.name}
							</MenuItem>
						))}
					</TextField>
					{ null && <Autocomplete
						onChange={(evt, newValue) => handleInput({ target: { name: 'category', value: newValue.value } })}
						options={(customFields?.categories ?? [])}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => <TextField {...params} name="category" margin="normal" fullWidth  label="Category" variant="outlined" />}
					/>}
					{(alertMsg.severity && alertMsg.title && alertMsg.text) ? (
						 <Alert severity={alertMsg.severity}>
							<AlertTitle>{alertMsg.title}</AlertTitle>
							<Typography sx={{ maxWidth: 320, wordBreak: 'break-word' }}>{alertMsg.text}</Typography>
						</Alert>
					) : null }
				</CardContent>
				<CardActions>
					<Button type="submit" disabled={open || submitted}>Submit</Button>
					{!submitted ? null : (
						<Button onClick={() => document.location.reload()}>Reset</Button>
					)}
				</CardActions>
			</Card>
			<Box sx={{ maxWidth: 480, margin: '0 auto', padding: '2rem' }}>
				<Link href="https://stepbystep-techsupport.zendesk.com/hc/en-us">Click here for the Help Center with self-help guides for the most common issues and questions</Link>
			</Box>
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
				open={open}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</Box>
	);
}

export default App;
